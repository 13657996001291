import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#112240', // Dark Blue
    },
    secondary: {
      main: '#0A192F', // Darker Blue
    },
    text: {
      primary: '#E5E5E5', // Bone White
      secondary: '#A8B2D1', // Slate
    },
    aquamarine: {
      main: '#64FFDA', // Aquamarine
    },
  },
});
